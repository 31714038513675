var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bill" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                !_vm.showDetail &&
                !_vm.showBill &&
                !_vm.declarationShow &&
                !_vm.excelImport,
              expression:
                "!showDetail&&!showBill&&!declarationShow&&!excelImport",
            },
          ],
          staticClass: "content",
        },
        [
          _c(
            "div",
            { staticClass: "flex-between-center head" },
            [
              _c(
                "div",
                { staticClass: "btn-box" },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        disabled: _vm.tenantBillStatus === 1,
                        type: "primary",
                        prefix: "add",
                      },
                      on: { click: _vm.addBillModel },
                    },
                    [_vm._v(" 创建账单 ")]
                  ),
                  _c(
                    "a-button",
                    {
                      staticClass: "ant-button",
                      attrs: {
                        prefix: "lock",
                        disabled: _vm.tenantBillStatus === null,
                      },
                      on: { click: _vm.lock },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.tenantBillStatus === 1 ? "解除锁定" : "锁定"
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "a-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.tenantBillStatus !== 1,
                          expression: "tenantBillStatus !== 1",
                        },
                      ],
                      staticClass: "ant-button",
                      attrs: { prefix: "shanchu" },
                      on: { click: _vm.delBill },
                    },
                    [_vm._v(" 删除账单 ")]
                  ),
                ],
                1
              ),
              _c("div"),
              _c("MonthPicker", {
                model: {
                  value: _vm.month,
                  callback: function ($$v) {
                    _vm.month = $$v
                  },
                  expression: "month",
                },
              }),
              _c("div"),
            ],
            1
          ),
          _c("div", { staticClass: "beauty-scroll center-content" }, [
            _c("div", { staticClass: "bill-head" }, [
              _c(
                "ul",
                { staticClass: "tenantBill" },
                [
                  _vm._l(_vm.monthTenantBill, function (item, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        staticClass: "detail",
                        class: { first: index === 0 },
                      },
                      [
                        _c("div", { staticClass: "bill-item" }, [
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm._f("stateFormatNum")(item.value))
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              _vm._s(item.label) +
                                _vm._s(
                                  item.label !== "缴纳人数"
                                    ? "(" + item.unit + ")"
                                    : ""
                                )
                            ),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  _c(
                    "li",
                    { staticClass: "detail lock-btn" },
                    [
                      _c(
                        "a-dropdown",
                        { attrs: { placement: "bottomLeft" } },
                        [
                          _c(
                            "a-button",
                            { attrs: { prefix: "daochu", suffix: "left" } },
                            [_vm._v("导出")]
                          ),
                          _c(
                            "a-menu",
                            { attrs: { slot: "overlay" }, slot: "overlay" },
                            [
                              _c(
                                "a-menu-item",
                                { on: { click: _vm.exportSummary } },
                                [_vm._v(" 导出汇总 ")]
                              ),
                              _c(
                                "a-menu-item",
                                { on: { click: _vm.exportBill } },
                                [_vm._v(" 导出明细 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-button",
                        {
                          staticClass: "ant-button",
                          attrs: { prefix: "daoru" },
                          on: {
                            click: function ($event) {
                              _vm.importParams.insureCompanyId = ""
                              _vm.excelImport = true
                            },
                          },
                        },
                        [_vm._v(" 导入 ")]
                      ),
                      _c(
                        "a-button",
                        {
                          staticClass: "ant-button",
                          attrs: { prefix: "chakan-chakanmingxi" },
                          on: {
                            click: function ($event) {
                              _vm.showBill = true
                            },
                          },
                        },
                        [_vm._v(" 查看明细 ")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.insureClassifyList.length > 0,
                      expression: "insureClassifyList.length>0",
                    },
                  ],
                  staticClass: "tenantDetail",
                },
                [
                  _c(
                    "ul",
                    { staticClass: "detail-head head-border" },
                    _vm._l(_vm.monthBillDetail, function (item, index) {
                      return _c("li", { key: index }, [
                        _vm._v(_vm._s(item.label)),
                      ])
                    }),
                    0
                  ),
                  _vm._l(_vm.insureClassifyList, function (item, index) {
                    return [
                      _c("ul", { key: index, staticClass: "detail-head" }, [
                        _c("li", { attrs: { span: 3 } }, [
                          _vm._v(_vm._s(item.insureClassifyName)),
                        ]),
                        _c("li", { attrs: { span: 3 } }, [
                          _vm._v(_vm._s(item.increaseCount)),
                        ]),
                        _c("li", { attrs: { span: 3 } }, [
                          _vm._v(_vm._s(item.decreasesCount)),
                        ]),
                        _c("li", { attrs: { span: 3 } }, [
                          _vm._v(_vm._s(item.personCount)),
                        ]),
                        _c("li", { attrs: { span: 3 } }, [
                          _vm._v(_vm._s(item.supplyCount)),
                        ]),
                        _c("li", { attrs: { span: 3 } }, [
                          _vm._v(
                            _vm._s(_vm._f("stateFormatNum")(item.companyTotal))
                          ),
                        ]),
                        _c("li", { attrs: { span: 3 } }, [
                          _vm._v(
                            _vm._s(_vm._f("stateFormatNum")(item.personTotal))
                          ),
                        ]),
                        _c("li", { attrs: { span: 3 } }, [
                          _vm._v(_vm._s(_vm._f("stateFormatNum")(item.total))),
                        ]),
                      ]),
                    ]
                  }),
                ],
                2
              ),
            ]),
            _c(
              "div",
              { staticClass: "bill_box" },
              [
                _c("BillList", {
                  ref: "billList",
                  attrs: { list: _vm.billList, month: _vm.month },
                  on: {
                    lookBill: _vm.lookBill,
                    voucher: _vm.voucher,
                    importBill: _vm.importBill,
                  },
                }),
              ],
              1
            ),
          ]),
          _c("AddBill", {
            ref: "addbill",
            attrs: { month: _vm.month },
            on: { change: _vm.createBill },
          }),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "link" },
                  on: { click: _vm.exportNotification },
                },
                [_vm._v(" 一键导出官方申报文件 ")]
              ),
              _c(
                "a-button",
                { attrs: { type: "link" }, on: { click: _vm.quickLogin } },
                [_vm._v(" 官方系统快捷登录 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showBill
        ? _c(
            "div",
            [
              _c("Table", {
                ref: "billTable",
                attrs: { month: _vm.month },
                on: {
                  back: function ($event) {
                    _vm.showBill = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showDetail
        ? _c(
            "div",
            [
              _vm.detailFlag
                ? _c("ViewBill", {
                    ref: "viewBill",
                    attrs: { "bill-param": _vm.billParam },
                    on: {
                      back: function ($event) {
                        return _vm.back()
                      },
                    },
                  })
                : _c("Voucher", {
                    on: {
                      back: function ($event) {
                        return _vm.back()
                      },
                    },
                  }),
            ],
            1
          )
        : _vm._e(),
      _vm.declarationShow
        ? _c(
            "div",
            [_c("SmartDeclaration", { on: { back: _vm.declarationBack } })],
            1
          )
        : _vm._e(),
      _c(
        "a-modal",
        {
          attrs: { title: "删除账单", "ok-text": "确认" },
          on: { ok: _vm.delConfirm },
          model: {
            value: _vm.delVisible,
            callback: function ($$v) {
              _vm.delVisible = $$v
            },
            expression: "delVisible",
          },
        },
        [_c("p", [_vm._v("确定要删除账单？")])]
      ),
      _c(
        "CbDrawer",
        {
          attrs: {
            closable: true,
            "is-mask": true,
            visible: _vm.urlVisible,
            title: "官方系统快捷登录",
            size: "normal",
            "overflow-auto": true,
            "display-btn": true,
            "show-cancel": false,
            "show-confirm": false,
          },
          on: {
            close: function ($event) {
              _vm.urlVisible = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "contentLogin" },
            _vm._l(_vm.quickList, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "commonpy" },
                [
                  _c("a-row", { staticClass: "title" }, [
                    _vm._v(_vm._s(item.companyName)),
                  ]),
                  _c(
                    "a-row",
                    [
                      _c("a-col", { attrs: { span: 6 } }, [
                        _vm._v("社保登录地址"),
                      ]),
                      _c(
                        "a-col",
                        {
                          staticClass: "loginUrl",
                          attrs: { span: 18 },
                          on: {
                            click: function ($event) {
                              return _vm.goLogin(item.socialUrl)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.socialUrl || "-"))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    [
                      _c("a-col", { attrs: { span: 6 } }, [
                        _vm._v("医保登录地址"),
                      ]),
                      _c(
                        "a-col",
                        {
                          staticClass: "loginUrl",
                          attrs: { span: 18 },
                          on: {
                            click: function ($event) {
                              return _vm.goLogin(item.medicalUrl)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.medicalUrl || "-"))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    [
                      _c("a-col", { attrs: { span: 6 } }, [
                        _vm._v("公积金登录地址"),
                      ]),
                      _c(
                        "a-col",
                        {
                          staticClass: "loginUrl",
                          attrs: { span: 18 },
                          on: {
                            click: function ($event) {
                              return _vm.goLogin(item.housingUrl)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.housingUrl || "-"))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          ),
        ]
      ),
      _vm.excelImport
        ? _c("ExcelImport", {
            attrs: {
              title: "导入账单",
              "bill-param": _vm.importParams,
              "import-template": "socialSecurityBill",
            },
            on: {
              goBack: function ($event) {
                _vm.excelImport = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }